import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import "react-circular-progressbar/dist/styles.css";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { WebAppConfig } from "../context/WebAppConfig";
import { webAppConfigIsVisible } from "../utils/isVisible";
import Banner from "../component/Home/Banner";
import { Image } from "react-bootstrap";
import Swal from "sweetalert2";
import { AiFillCheckCircle } from "react-icons/ai";
import ScrachCardModal from "../component/ScrachCardModal/ScrachCardModal";
import { useAuth } from "../context/auth-context";
import AddressPopup from "../component/Home/AddressPopup";
import greate_white from "/assets/images/greate_white/greate_white.png";

const Home = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const webAppConfigData = useContext(WebAppConfig);
  const [cmsValues, setCmsValues] = useState();
  const [showBanner, setShowBanner] = useState(false);
  const [kycVerify, setKycVerify] = useState(false);
  const [scratchCardData, setScratchCardData] = useState([]);
  const [show, setShow] = useState(true);
  const [userApproved, setUserApproved] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  // const [loading, setLoading] = useState(true);

  const { user } = useAuth();

  const setShowGreateWhitePopup = () => {
    Swal.fire({
      imageUrl: greate_white,
      imageHeight: "90%",
      imageAlt: "greate_white.png",
      showConfirmButton: false,
      showCloseButton: true
    });
  }

  const setDistrictData = async (user_Details = userDetails) => {
    const [districtError, districtResult] = await asyncWrap(
      axios.get('/districtList', {
        params: { stateId: user_Details?.StateId },
      })
    );
    if (!districtResult) {
      console.log(districtError);
      return;
    }

    const districtOptions = districtResult.data?.data.reduce((options, district) => {
      options[district.districtId] = district.districtName;
      return options;
    }, {});

    const { value: districtId } = await Swal.fire({
      title: 'Select District',
      input: 'select',
      inputOptions: districtOptions,
      inputPlaceholder: 'Select District',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      inputValidator: value => {
        if (!value) {
          return 'Select District!';
        }
      },
    });

    if (districtId) {
      const [error, result] = await asyncWrap(
        axios.post('/editProfile', {
            userType: user_Details.roleName,
            userId: user?.profile?.userId || user.userId,
            district: districtId
        })
      );
      if (!result) {
        Swal.fire(error.response.data.data.message);
        history.replace('/home');
      } else {
        Swal.fire(result?.data?.message).then(() => {
          if (!user_Details?.pinCode) {
            setShowAddressPopup(true);
          } else if(user_Details?.roleName === 'Retailer') {
            setShowGreateWhitePopup()
          }
        });
      }
    }
  }
  const setDistrictStateData = async (user_Details = userDetails) => {

    const [stateError, stateResult] = await asyncWrap(axios.get('/stateList'));
    
    if (!stateResult) {
      console.log(stateError);
      return;
    }

    const stateOptions = stateResult.data?.data.reduce((options, state) => {
      options[state.stateId] = state.stateName;
      return options;
    }, {});

    const { value: stateId } = await Swal.fire({
      title: 'Select State',
      input: 'select',
      inputOptions: stateOptions,
      inputPlaceholder: 'Select State',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      inputValidator: value => {
        if (!value) {
          return 'Select State!';
        }
      },
    });

    if (stateId) {

      const [districtError, districtResult] = await asyncWrap(
        axios.get('/districtList', {
          params: { stateId: stateId },
        })
      );
      if (!districtResult) {
        console.log(districtError);
        return;
      }
  
      const districtOptions = districtResult.data?.data.reduce((options, district) => {
        options[district.districtId] = district.districtName;
        return options;
      }, {});
  
      const { value: districtId } = await Swal.fire({
        title: 'Select District',
        input: 'select',
        inputOptions: districtOptions,
        inputPlaceholder: 'Select District',
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        inputValidator: value => {
          if (!value) {
            return 'Select District!';
          }
        },
      });
  
      if (districtId) {
        const [error, result] = await asyncWrap(
          axios.post('/editProfile', {
            userType: user_Details.roleName,
            userId: user?.profile?.userId || user.userId,
            state: stateOptions[stateId],
            district: districtId
          })
        );
        if (!result) {
          Swal.fire(error.response.data.data.message);
          history.replace('/home');
        } else {
          Swal.fire(result?.data?.message).then(() => {
            if (!user_Details?.pinCode) {
              setShowAddressPopup(true);
            } else if(user_Details?.roleName === 'Retailer') {
              setShowGreateWhitePopup()
            }
          });
        }
      }
    }
    
  }

  useEffect(() => {
    getCmsValues();
    getScratchCard();
    getUserProfile();
    // eslint-disable-next-line
  }, []);

  const GetPointInfo = async (user_Details = userDetails) => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      return;
    }

    const [ConfigError, ConfigResult] = await asyncWrap(axios.get("config"));
    if (ConfigError) {
      console.log(error);
    }
    const rationofpoint = ConfigResult.data.data.filter(
      item => item.FlagName === "RatioOfPoints"
    )[0].Value;
    console.log(rationofpoint);

    const point =
      result.data.data?.totalPoints[0]?.point -
      result.data.data?.currentYearPoints[0].EarnPoints;
    const gift_point = result.data.data?.totalPoints[1]?.point;

    if (
      result.data.data?.totalPoints[0]?.point > 15000 * rationofpoint &&
      !user_Details.IsPanVerified
    ) {
      const { value: PancardValue } = await Swal.fire({
        title: "Enter your Pancard",
        input: "text",
        inputLabel: "Your Pan Card",
        inputValue: user_Details?.PancardNumber,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        inputValidator: value => {
          if (!value) {
            return "You need to write something!";
          }
          let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
          if (!pancardpattern.test(value)) {
            return "Please enter valid pan number";
          }
        },
      });

      if (PancardValue) {
        let pancardpattern = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/);
        if (!pancardpattern.test(PancardValue)) {
          Swal.fire("Please enter valid pan number");
          return;
        }

        const [panError, panResult] = await asyncWrap(
          axios.post("/verifyKyc/verify-pan", {
            panNumber: PancardValue,
          })
        );

        if (!panResult) {
          Swal.fire(panError.response.data.data.message).then(() => {
            window.location.reload();
          });
          return;
        }
        if (!panResult.data.data.valid) {
          Swal.fire(panResult.data.data.message).then(() => {
            window.location.reload();
          });
          return;
        } else {
          const [error, result] = await asyncWrap(
            axios.post("/editProfile", {
              userType: user_Details.roleName,
              kycTypeId: "Pan Card",
              userId: user?.profile?.userId || user.userId,
              panNumber: PancardValue,
              kycNumber: PancardValue,
              ispanverify: 1,
            })
          );
          if (!result) {
            Swal.fire(error.response.data.data.message);
            history.replace("/home");
          } else if (result.data?.success) {
            Swal.fire(result.data?.message);
            history.replace("/home");
          } else {
            Swal.fire(result?.data?.message).then(() => {
              window.location.reload();
            });
          }
        }
      }
    } else {
      if (!user_Details?.StateId && !user_Details?.districtId) {
        setDistrictStateData(user_Details)
      }
      else if (user_Details.StateId && !user_Details?.districtId) {
        setDistrictData(user_Details)
      } else if (user_Details.StateId === "0") {
        setDistrictStateData(user_Details)
      } else if (user_Details.districtId === "0") {
        setDistrictData(user_Details)
      }
      else if (!user_Details?.pinCode) {
        setShowAddressPopup(true);
      } else if(user_Details?.roleName === 'Retailer') {
        setShowGreateWhitePopup()
      }
    }
    // if (!user_Details?.pinCode) {
    //   setShowAddressPopup(true);
    // }
  };

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire("Please Logout and login again");
      return;
    }
    if (!result) {
      console.log(error);
      return;
    } else {
      const user_data = result?.data?.data;
      setUserDetails(result?.data?.data);
      setUserApproved(result.data.data?.callverification);
      if (
        result.data.data?.IsAadharVerified &&
        result.data.data?.IsPanVerified
      ) {
        setKycVerify(true);
      }
      if (!result.data.data.roleId) {
        const { value: roleName } = await Swal.fire({
          title: "Select Role",
          input: "select",
          inputOptions: {
            Retailer: "Retailer",
            Electrician: "Electrician",
          },
          inputPlaceholder: "Select Role",
          showCancelButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          inputValidator: value => {
            if (!value) {
              return "Select role!";
            }
          },
        });

        if (roleName) {
          const [error, result] = await asyncWrap(
            axios.post("/editProfile", {
              userType: roleName,
              userId: user?.profile?.userId || user.userId,
            })
          );
          if (!result) {
            Swal.fire(error.response.data.data.message);
            history.replace("/home");
          } else if (result.data?.success) {
            Swal.fire(result.data?.message).then(() => {
              localStorage.clear();
              setToken(null);
              window.location.reload();
            });
            // history.replace("/home");
          } else {
            Swal.fire(result?.data?.message).then(() => {
              window.location.reload();
            });
          }
        }
      } else {
        GetPointInfo(user_data);
      }
    }
  };

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setCmsValues(result.data.data);
  };

  const getScratchCard = async () => {
    const [error, result] = await asyncWrap(axios.get("/scratch-card"));
    if (!result) {
      console.log(error);
      return;
    }

    setScratchCardData(result.data.data.filter(item => !item.IsScrached));
    // setScratchCardData(data);
    // console.log(scratchCardData)
  };

  return (
    <div>
      {scratchCardData.length !== 0 && show ? (
        <ScrachCardModal show={show} setShow={setShow} />
      ) : (
        <div>
          {userDetails && (
            <AddressPopup
              show={showAddressPopup}
              setShow={setShowAddressPopup}
              userDetail={userDetails}
            />
          )}
          <Banner data={cmsValues} show={showBanner} setShow={setShowBanner} />
            <div className="d-flex flex-column">
              <div id="carousel_1"></div>
            <div className="horizontal-scroll">
              {cmsValues &&
                cmsValues?.HomePageBanner?.map((data, index) => (
                  <Image
                    key={index}
                    className="img-fluid for-light  rounded-4"
                    style={{
                      width: "auto",
                      marginLeft: "20px",
                      height: "125px",
                    }}
                    onClick={() => {
                      const newWindow = window.open(
                        data?.WebLink || data?.PDFPath
                      );
                      if (newWindow) newWindow.opener = null;
                    }}
                    src={data.BannerImage}
                    alt={data.BannerImage}
                  />
                ))}
            </div>
            <div className="mt-3">
              {!userApproved ? (
                <div className="d-flex flex-row flex-wrap justify-content-center mx-2">
                  <div
                    onClick={() => {
                      if (
                        (user?.profile?.roleName || user?.roleName) ===
                        "Retailer"
                      ) {
                        history.push(`/edit-retailer/${user.userId}`);
                      } else {
                        history.push(`/edit-profile/${user.userId}`);
                      }
                    }}
                    style={{ width: "44%" }}
                    className="d-flex flex-column shadow m-2 p-3 rounded-4 justify-content-center"
                  >
                    <div style={{ color: "#616161" }} className="fs-6 fw-bold">
                      {t("edit_profile")}
                    </div>
                  </div>

                  <div
                    onClick={() => history.push("/kycverify")}
                    style={{ width: "44%", position: "relative" }}
                    className="d-flex flex-column shadow m-2 p-3 rounded-4 justify-content-center"
                  >
                    <>
                      {kycVerify ? (
                        <div
                          style={{
                            position: "absolute",
                            width: "40px",
                            height: "40px",
                            top: 0,
                            right: 0,
                          }}
                        >
                          <AiFillCheckCircle
                            style={{
                              height: "auto",
                              width: "60%",
                              marginTop: "12px",
                              color: "green",
                            }}
                          />
                        </div>
                      ) : null}
                      <div
                        style={{ color: "#616161" }}
                        className="fs-6 fw-bold"
                      >
                        KYC
                      </div>
                    </>
                  </div>
                </div>
              ) : null}
              <div className="d-flex flex-row flex-wrap justify-content-center mx-2 mt-1">
                <div
                  onClick={() => history.push("/redeem")}
                  style={{ width: "44%" }}
                  className="d-flex flex-column shadow m-2 p-3 rounded-4 justify-content-center"
                >
                  <div style={{ color: "#616161" }} className="fs-6 fw-bold">
                    {t("redeem")}
                  </div>
                </div>

                <div
                  onClick={() => {
                    history.push("/customer-chat");
                  }}
                  style={{ width: "44%" }}
                  className="d-flex flex-column shadow m-2 p-3 rounded-4 justify-content-center"
                >
                  <div style={{ color: "#616161" }} className="fs-6 fw-bold">
                    {t("customer_care")}
                  </div>
                </div>
              </div>
            </div>

            {userApproved ? (
              <div
                style={{ position: "relative" }}
                className="d-flex flex-column shadow p-3 rounded-4 text-start m-3"
              >
                <div className="d-flex flex-row justify-content-between">
                  <div
                    style={{ width: "100%" }}
                    className="d-flex flex-column justify-content-between"
                  >
                    <div>
                      <div
                        style={{ color: "#434343", fontSize: "16px" }}
                        className="fw-normal"
                      >
                        Welcome {user?.profile?.fullName || user?.fullName},{" "}
                        {(user?.profile?.roleName || user?.roleName) === "Electrician" ? "Influencer" : user?.profile?.roleName || user?.roleName}
                      </div>
                      <div
                        style={{ color: "#434343", fontSize: "18px" }}
                        className="fw-bold"
                      >
                        {userDetails?.mobileNumber}
                      </div>
                      <div
                        style={{
                          color: "rgb(58, 105, 221)",
                          fontWeight: 600,
                          width: "80%",
                          fontSize: "18px",
                        }}
                      >
                        {t("user_verified")}
                      </div>
                    </div>
                  </div>
                  <div className="align-self-center">
                    <img
                      alt="img"
                      src="/assets/images/icons/CustomerVerified.png"
                      style={{ width: "60px" }}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="d-flex flex-column shadow p-3 rounded-4 text-start m-3">
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-column justify-content-between">
                  <div>
                    <div
                      style={{ color: "#434343", fontSize: "18px" }}
                      className="fw-bold"
                    >
                      {t("points_information")}
                    </div>
                  </div>
                  <div
                    onClick={() => history.push("/points-info")}
                    className="fw-bold"
                    style={{ color: "#3A69DD", fontSize: "16px" }}
                  >
                    {t("view")}
                  </div>
                </div>
                {/* <div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      marginTop: "10px",
                      marginRight: "10px",
                      borderRadius: "50%",
                      background: "#1D267D",
                    }}
                  ></div> */}
                {/* <img alt="img" src={ImgSchemes} style={{ width: "100%" }} /> */}
                {/* </div> */}
              </div>
            </div>
            <div id="carousel_2"></div>
            {webAppConfigIsVisible("Scheme List", webAppConfigData) && (
              <div className="d-flex flex-column shadow p-3 rounded-4 text-start m-3">
                <div className="d-flex flex-row">
                  <div className="d-flex flex-column">
                    <div
                      style={{ color: "#434343", fontSize: "18px" }}
                      className="fw-bold"
                    >
                      {t("schemes")}
                    </div>
                    <div
                      style={{
                        color: "#7B7B7B",
                        width: "80%",
                        fontSize: "16px",
                      }}
                    >
                      {t("schemes_desc")}
                    </div>
                  </div>
                  <div className="align-self-center">
                    <img
                      alt="img"
                      src="/assets/images/icons/Scheme.png"
                      style={{ width: "60px" }}
                    />
                  </div>
                </div>

                <div
                  onClick={() => history.push("schemes")}
                  className="fw-bold"
                  style={{ color: "#3A69DD", fontSize: "16px" }}
                >
                  {t("view")}
                </div>
              </div>
            )}

            <div className="d-flex flex-column shadow p-3 rounded-4 text-start m-3">
              <div className="d-flex flex-row">
                <div className="align-self-center">
                  <img
                    alt="img"
                    src="/assets/images/icons/Brochure.png"
                    style={{ width: "60px" }}
                  />
                </div>

                <div className="d-flex text-end flex-column align-items-end">
                  <div
                    style={{ color: "#434343", fontSize: "18px" }}
                    className="fw-bold"
                  >
                    {t("brochure")}
                  </div>
                  <div
                    className="text-end"
                    style={{
                      color: "#7B7B7B",
                      width: "80%",
                      fontSize: "16px",
                    }}
                  >
                    {t("brochure_desc")}
                  </div>
                </div>
              </div>

              <div
                onClick={() => history.push("/brochure")}
                className="fw-bold text-end mt-1"
                style={{ color: "#3A69DD", fontSize: "16px" }}
              >
                {t("view")}
              </div>
            </div>
            <div id="carousel_3"></div>
            <div className="d-flex flex-column shadow p-3 rounded-4 text-start m-3">
              <div className="d-flex flex-row">
                <div className="d-flex flex-column">
                  <div
                    style={{ color: "#434343", fontSize: "18px" }}
                    className="fw-bold"
                  >
                    {t("events")}
                  </div>
                  <div
                    style={{
                      color: "#7B7B7B",
                      width: "80%",
                      fontSize: "16px",
                    }}
                  >
                    {t("event_desc")}
                  </div>
                </div>
                <div className="align-self-center">
                  <img
                    alt="img"
                    src="/assets/images/icons/Events.png"
                    style={{ width: "60px" }}
                  />
                </div>
              </div>
              <div
                onClick={() => history.push("events")}
                className="fw-bold"
                style={{ color: "#3A69DD", fontSize: "16px" }}
              >
                {t("view")}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// const StyledBorderLessCard = styled(Card)`
//   height: 200px;
//   margin-bottom: 20px;
//   margin-top: 10px;
//   box-shadow: 0px 8px 30px #0000001a;
//   border: 0;
// `;
export default Home;
